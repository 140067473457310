import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "./context";
import TooltipWrapper from "./fragments/TooltipWrapper";
import MobileInstallInstructions from './components/MobileInstallInstructions'
import { REACT_APP_VERSION } from './components/envs';
import { isMobile } from './customhooks/useMobile';
import * as Icons from 'react-bootstrap-icons';

const ESCAPE_KEY = 27;

const BetterLeftNav = ({ subsections, helpModalTargetId, unseenCount, ...props }) => {
  const [visibleSubsection, setVisibleSubsection] = useState();
  const { loggedInUser, api } = useContext(UserContext);
  const [showMobileAddPopup, setShowMobileAddPopup] = useState(false);

  const mobile = isMobile();

  useEffect(() => {
    const _handleKeydown = (event) => {
      if (event.keyCode === ESCAPE_KEY) setVisibleSubsection(null);
    };
    document.addEventListener("keydown", _handleKeydown);
    return () => {
      document.removeEventListener("keydown", _handleKeydown);
    };
  }, []);

  const toggle = (subsection) => {
    let updatedVisibleSubsection = null;
    if (!visibleSubsection || visibleSubsection !== subsections[subsection]) {
      updatedVisibleSubsection = subsections[subsection];
    }
    setVisibleSubsection(updatedVisibleSubsection);
  };

  const selectSubsectionItem = (item) => {
    if (item.component) {
      setVisibleSubsection((prev) => ({ ...prev, items: prev.items.map((i) => ({ ...i, selected: i === item ? true : false })) }));
    } else if (item.onClick) {
      item.onClick();
      setVisibleSubsection(null);
    }
  };

  const downloadApp = async (mono) => {
    // const file = await api.downloadMono();
    const link = document.createElement("a");
    // link.href = window.URL.createObjectURL(new Blob([file.data]));
    link.href = `${window._env_.REACT_APP_SERVER_URL || 'https://new.program-legislatie.ro'}/download/${mono ? 'monopost' : 'lexonline'}`;
    // link.setAttribute('download', 'mono.md'); // TODO - rename file
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    const _handleMousedown = (event) => {
      const contentVisible = document.getElementsByClassName("subsection-content"),
        menu = document.getElementsByClassName("menu")[0],
        { top, bottom, right } = contentVisible.length
          ? document.getElementById("left-sidebar").getBoundingClientRect()
          : menu.getBoundingClientRect(),
        left = 0,
        controlRect = { top, bottom, left, right };
      // console.log(controlRect)
      // console.log(event)

      if (
        event.clientX < controlRect.left ||
        event.clientX > controlRect.right ||
        event.clientY < controlRect.top ||
        event.clientY > controlRect.bottom
      ) {
        setVisibleSubsection(null);
      }
    };
    document.addEventListener("mousedown", _handleMousedown);
    return () => document.removeEventListener("mousedown", _handleMousedown);
  }, [visibleSubsection]);

  const renderVisibleSubsection = () => {
    const items = visibleSubsection ? visibleSubsection.items : [];
    return (
      <>
        {items.length > 0 ? (
          <div className="subsection d-flex pt-3 flex-column justify-items-start align-items-start">
            {items.map((item, index) => {
              const { title, selected, badge } = item,
                titleParts = title.split(/\n/);
              return (
                <div
                  key={`sub-${index}`}
                  className={`position-relative subsection-item py-1 px-2 ${selected ? "selected" : ""}`}
                  onClick={() => selectSubsectionItem(item)}
                >
                  {titleParts.map((tp, tpindex) => (
                    <div key={`sub-${index}-${tpindex}`}>{tp}</div>
                  ))}
                  {badge && props[badge.prop] ? (
                    <div className={`badge badge-pill badge-${badge.type}`} style={{ position: "absolute", top: -5, right: -5 }}>
                      {props[badge.prop]}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  };
  const renderVisibleSubsectionComponent = () => {
    let component = null,
      id = "whatever";
    if (visibleSubsection) {
      const selectedItems = visibleSubsection.items.filter((i) => i.selected),
        selectedItem = selectedItems ? selectedItems[0] : null;
      if (selectedItem) {
        component = selectedItem.component;
        id = selectedItem.title.toLowerCase().replace(" ", "-");
      }
    }
    return (
      <>
        {component ? (
          <div className="subsection-content" id={id}>
            {component}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
    <div id="left-sidebar" className={`left-sidebar ${visibleSubsection ? "opened" : ""}`}>
      <div className="buttons d-flex flex-column justify-content-between align-items-stretch">
        <div className='mt-auto'>
          <TooltipWrapper
            className="pl-1 lex-tooltip-pl"
            desc='Index'
            placement="right"
          >
            <div className="btn btn-metallic" onClick={() => toggle("calendar")}>
              <i className="fa fa-calendar"></i>
            </div>
          </TooltipWrapper>
          <TooltipWrapper
            className="pl-1 lex-tooltip-pl"
            desc='Căutare rapidă'
            placement="right"
          >
            <div className="btn btn-metallic" onClick={() => toggle("tipacte")}>
              <i className="fa fa-book"></i>
            </div>
          </TooltipWrapper>
          <TooltipWrapper
            className="pl-1 lex-tooltip-pl"
            desc='Acte monitorizate'
            placement="right"
          >
            <div className="btn btn-metallic position-relative" onClick={() => toggle("actelemele")}>
              <i className="fa fa-folder-open"></i>
              {
                !unseenCount
                  ? null
                  : <div className="badge badge-pill badge-warning fs-14" style={{ position: "absolute", top: 0, right: 5 }}>
                    {unseenCount}
                  </div>
              }
            </div>
          </TooltipWrapper>
          <TooltipWrapper
            className="pl-1 lex-tooltip-pl"
            desc='Creează shortcut LexOnline'
            placement="right"
          >
            <div className="btn btn-metallic position-relative" onClick={() => downloadApp(false)}>
              <i className='fa fa-cloud-download' />
            </div>
          </TooltipWrapper>
          <TooltipWrapper
            className="pl-1 lex-tooltip-pl"
            desc='Instrucțiuni instalare aplicație mobile'
            placement="right"
          >
            <div className="btn btn-metallic" onClick={() => setShowMobileAddPopup(true)}>
              <i className="fa fa-mobile"></i>
            </div>
          </TooltipWrapper>
          {
            loggedInUser.canLogin
              ? <TooltipWrapper
                className="pl-1 lex-tooltip-pl"
                desc='Downloadeaza monopost'
                placement="right"
              >
                <div className="btn btn-metallic position-relative" onClick={() => downloadApp(true)}>
                  <i className='fa fa-download' />
                </div>
              </TooltipWrapper>
              : null
          }
          {/* <div className="btn btn-metallic" onClick={() => this.toggle('documente')}>
              <i className="fa fa-folder-open"></i>
            </div>
            <div className="btn btn-metallic" onClick={() => this.toggle('alerte')}>
              <i className="fa fa-bell"></i>
            </div> */}
        </div>
        <div
          className="btn btn-metallic btn-help mt-auto mb-0"
          data-toggle="modal"
          data-target={`#${helpModalTargetId}`}
          onClick={() => setVisibleSubsection(null)}
        >
          <span className="fa-stack fs-18">
            <i className="fa fa-circle-thin fa-stack-2x"></i>
            <i className="fa fa-question fa-stack-1x"></i>
          </span>
        </div>
        <div style={{ color: 'white', textAlign: 'center' }}>
          <span className="hide4print text-muted small"><small>v{REACT_APP_VERSION}</small></span>
        </div>
      </div>
      {/* {visibleSubsection ? ( */}
      <div className="subsections">
        <div className="menu">{renderVisibleSubsection()}</div>
        <div className="content">{renderVisibleSubsectionComponent()}</div>
      </div>
      {/* ) : null} */}
    </div>
    {
      loggedInUser && showMobileAddPopup && !mobile
      ? <MobileInstallInstructions handleClose={() => setShowMobileAddPopup(false)} />
      : null
    }
    </>
  );
};

export default BetterLeftNav;
